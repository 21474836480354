import { useEffect, useState } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend/index';
import createAvatar from '../utils/createAvatar';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (user?.photoUrl) {
      setAvatar(user?.photoUrl);
    }
  }, [user?.photoUrl]);

  return (
    <MAvatar src={avatar} alt={user?.candidate?.name} color={createAvatar(user?.candidate?.name).color} {...other}>
      {createAvatar(user?.candidate?.name).name}
    </MAvatar>
  );
}
