import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  DialogContent,
  Stack
} from '@material-ui/core';
import { styled } from '@material-ui/system';
// utils
import { fDateTimePTBR, fToNow } from '../../utils/formatTime';
import { NotificationTypeIcon } from '../../utils/notificationTypes';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
// hooks
import useNotification from '../../hooks/useNotification';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { DialogAnimate } from '../../components/animate';
import Markdown from '../../components/Markdown';

// ----------------------------------------------------------------------

const MarkdownWrapperStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
      {notification.message}
    </Typography>
  );
  return {
    title,
    avatar: (
      <img
        alt="notification"
        src={
          NotificationTypeIcon[notification.type]
            ? NotificationTypeIcon[notification.type].icon
            : '/static/icons/selective_icon.svg'
        }
      />
    )
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

function NotificationItem({ notification, onClick }) {
  const { avatar, title } = renderContent(notification);
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <ListItemButton
        to={
          notification.type === 3 && notification.invitationAccepted === null
            ? `${PATH_DASHBOARD.selectiveProcesses.view(notification.selectiveProcessId)}?isNotification=y`
            : '#'
        }
        onClick={() => {
          if (notification.type === 6) setOpenDialog(true);
          else onClick();
        }}
        disableGutters
        component={RouterLink}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.isUnRead && {
            bgcolor: 'action.selected'
          })
        }}
      >
        <ListItemAvatar>{avatar && <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>}</ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
              {fToNow(notification.creationDate)}
            </Typography>
          }
        />
      </ListItemButton>
      <DialogAnimate
        fullWidth
        open={openDialog}
        maxWidth="sm"
        scroll="paper"
        sx={{ borderRadius: '1rem' }}
        onClose={() => setOpenDialog(false)}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mx: '1.5rem', mt: '1rem' }}>
          <Stack>
            <Typography variant="subtitle1">{notification.message}</Typography>
            <Typography variant="body2" color="grey.600">
              Enviado em {fDateTimePTBR(notification.creationDate)}
            </Typography>
          </Stack>
          <MIconButton
            sx={{ width: '2rem', height: '2rem' }}
            color="primary"
            size="small"
            onClick={() => setOpenDialog(false)}
          >
            <Icon icon={closeFill} />
          </MIconButton>
        </Stack>
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" sx={{ p: 3 }}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'primary.main',
                width: '31.25rem',
                height: '6.25rem',
                borderRadius: '1rem',
                mb: '0.5rem'
              }}
            >
              <Box component="img" src="/static/icons/qvende_white.svg" sx={{ height: '5rem' }} />
            </Stack>
            <MarkdownWrapperStyle>
              <Markdown children={notification.html} />
            </MarkdownWrapperStyle>
            <Stack
              spacing={4}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'primary.darker',
                width: '31.25rem',
                height: '5rem',
                borderRadius: '1rem',
                mt: '0.5rem'
              }}
            >
              <Box component="img" src="/static/icons/Qvende_logo_people.svg" sx={{ width: '1.938rem' }} />
              <Stack spacing={1}>
                <Stack direction="row" spacing={5}>
                  <Typography variant="button" color="primary.lighter" fontSize="11px">
                    Termos de Uso
                  </Typography>
                  <Typography variant="button" color="primary.lighter" fontSize="11px">
                    Política de Privacidade
                  </Typography>
                </Stack>
                <Typography color="white" fontSize="10px">
                  © QVende - 2022 | Todos os direitos reservados.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </DialogAnimate>
    </>
  );
}

NotificationsPopover.propTypes = {
  color: PropTypes.string
};

export default function NotificationsPopover({ color }) {
  const { notifications, readAllNotifications } = useNotification();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notificationsReaded, setNotificationsReaded] = useState([]);
  const [notificationsUnreaded, setNotificationsUnreaded] = useState([]);

  useEffect(() => {
    if (notifications.length > 0) {
      setNotificationsReaded(notifications.filter((notification) => !notification.unread));
      setNotificationsUnreaded(notifications.filter((notification) => notification.unread));
    }
  }, [notifications]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (notificationsUnreaded.length > 0) readAllNotifications();
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={color}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={notificationsUnreaded.length} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificações</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Você tem {notificationsUnreaded.length} mensagens não lidas
            </Typography>
          </Box>

          {notificationsUnreaded.length > 0 && (
            <Tooltip title="Ler todas">
              <MIconButton color="primary" onClick={readAllNotifications}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ maxHeight: { xs: 340, sm: 500 } }}>
          <List
            disablePadding
            subheader={
              notificationsUnreaded.length > 0 ? (
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Novo
                </ListSubheader>
              ) : null
            }
          >
            {notificationsUnreaded.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={handleClose} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Anteriores
              </ListSubheader>
            }
          >
            {notificationsReaded.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={handleClose} />
            ))}
          </List>
        </Scrollbar>

        <Divider />
        {/* 
        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
