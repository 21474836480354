// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const NavbarConfig = [
  {
    title: 'Início',
    path: PATH_DASHBOARD.root
  },
  {
    title: 'Buscar vagas',
    path: PATH_DASHBOARD.general.listSelectiveProcesses
  },
  {
    title: 'Meu Currículo',
    path: PATH_DASHBOARD.user.root
  },
  {
    title: 'Minhas Vagas',
    path: PATH_DASHBOARD.general.mySelectiveProcesses
  }
];

export default NavbarConfig;
