// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    listSelectiveProcesses: path(ROOTS_DASHBOARD, '/listSelectiveProcesses'),
    mySelectiveProcesses: path(ROOTS_DASHBOARD, '/mySelectiveProcesses')
  },
  tests: {
    competencyTestIntro: path(ROOTS_DASHBOARD, '/tests'),
    competencyTest: path(ROOTS_DASHBOARD, '/tests/competencyTest')
  },
  valuesTest: {
    intro: path(ROOTS_DASHBOARD, '/valuesTest'),
    answer: path(ROOTS_DASHBOARD, '/valuesTest/answer')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    edit: path(ROOTS_DASHBOARD, '/user/edit'),
    settings: path(ROOTS_DASHBOARD, '/user/settings'),
    addVideoResume: path(ROOTS_DASHBOARD, '/user/addVideoResume'),
    viewVideoResume: path(ROOTS_DASHBOARD, '/user/viewVideoResume')
  },
  selectiveProcesses: {
    root: path(ROOTS_DASHBOARD, '/listSelectiveProcesses'),
    view: (selectiveProcessId) =>
      path(ROOTS_DASHBOARD, `/listSelectiveProcesses/${selectiveProcessId}/viewSelectiveProcess`)
  },
  progressSelectiveProcess: {
    root: (selectiveProcessId) => path(ROOTS_DASHBOARD, `/progressSelectiveProcess/${selectiveProcessId}`),
    auxiliaryRequirementsTest: (selectiveProcessId) =>
      path(ROOTS_DASHBOARD, `/progressSelectiveProcess/${selectiveProcessId}/auxiliaryRequirementsTest`),
    auxiliaryQuestionsTest: (selectiveProcessId) =>
      path(ROOTS_DASHBOARD, `/progressSelectiveProcess/${selectiveProcessId}/auxiliaryQuestionsTest`),
    addVideoInterview: (selectiveProcessId) =>
      path(ROOTS_DASHBOARD, `/progressSelectiveProcess/${selectiveProcessId}/addVideoInterview`),
    viewVideoInterview: (selectiveProcessId) =>
      path(ROOTS_DASHBOARD, `/progressSelectiveProcess/${selectiveProcessId}/viewVideoInterview`),
    customTest: (selectiveProcessId) =>
      path(ROOTS_DASHBOARD, `/progressSelectiveProcess/${selectiveProcessId}/customTest`)
  }
};
