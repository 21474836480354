import { Link as RouterLink, matchPath } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Container } from '@material-ui/core';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useSearchProcess from '../../hooks/useSearchProcess';
// components
import Logo from '../../components/logos/Logo';
import { MHidden } from '../../components/@material-extend';
import NavbarSection from '../../components/NavbarSection';
import AccountPopover from './AccountPopover';
import NavbarConfig from './NavbarConfig';
import NotificationsPopover from './NotificationsPopover';
// Routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { MotionInView, varFadeIn } from '../../components/animate';

// ----------------------------------------------------------------------

const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 44;
const APPBAR_DESKTOP = 64;

const APP_BAR_MOBILE_WITH_SEARCH = 190;
const APP_BAR_DESKTOP_WITH_SEARCH = 64;
const APP_BAR_MOBILE = 56;
const APP_BAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(() => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  transition: 'height 0.2s, background 0.2s'
}));

const ToolbarStyle = styled(Stack)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {};

export default function DashboardNavbar() {
  const { isCollapse } = useCollapseDrawer();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { querys, changeQuerys, searchProcess, resetQuerys } = useSearchProcess();

  function handleChangeIsSearching() {
    searchProcess();
    navigate(`${PATH_DASHBOARD.general.listSelectiveProcesses}`);
  }

  return (
    <RootStyle
      sx={{
        mb: '3.125rem',
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        }),
        height:
          matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
          matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
          pathname.includes('viewSelectiveProcess')
            ? APP_BAR_MOBILE
            : APP_BAR_MOBILE,
        [theme.breakpoints.up('lg')]: {
          height:
            matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
            matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
            pathname.includes('viewSelectiveProcess')
              ? APP_BAR_DESKTOP_WITH_SEARCH
              : APP_BAR_DESKTOP
        },
        background:
          matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
          matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
          pathname.includes('viewSelectiveProcess')
            ? '#FFF'
            : '#FFF',
        boxShadow:
          !matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
          !matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
          !pathname.includes('viewSelectiveProcess')
            ? '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 12px 24px rgba(145, 158, 171, 0.24)'
            : undefined
      }}
    >
      <Container maxWidth="xl">
        <ToolbarStyle direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to={PATH_DASHBOARD.root}>
            <Logo
              color={
                matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
                matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
                pathname.includes('viewSelectiveProcess')
                  ? 'dark'
                  : 'dark'
              }
            />
          </Box>

          <MHidden width="mdDown">
            <NavbarSection navConfig={NavbarConfig} />
          </MHidden>

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 5 }}>
            <NotificationsPopover
              color={
                matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
                matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
                pathname.includes('viewSelectiveProcess')
                  ? 'primary'
                  : 'primary'
              }
            />
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
        {!!(
          matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
          matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
          pathname.includes('viewSelectiveProcess')
        ) && <MotionInView transition={{ duration: 10 }} variants={varFadeIn} />}
      </Container>
    </RootStyle>
  );
}
