import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, Alert, TextField, IconButton, InputAdornment, Box, Typography, Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MHidden, MIconButton } from '../../@material-extend';
import LabelFields from '../../LabelFields';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O email deve ser um endereço de email válido').required('O email é obrigatório'),
    password: Yup.string().required('A senha é obrigatória')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        enqueueSnackbar('Login efetuado com sucesso', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
        if (error.statusCode === 401) {
          setErrors({ afterSubmit: 'Email e/ou senha estão erradas' });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <Box>
            <LabelFields title="Email" />
            <TextField
              fullWidth
              autoComplete="username"
              placeholder="Digite seu e-mail..."
              type="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Box>

          <Box>
            <LabelFields title="Senha" />
            <TextField
              fullWidth
              placeholder="Digite sua senha..."
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Box>
        </Stack>

        <Grid container alignItems="center" sx={{ mt: '2.25rem' }}>
          <MHidden width="smDown">
            <Grid item xs={12} sm={6}>
              <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
                <Typography variant="button">Esqueci minha senha</Typography>
              </Link>
            </Grid>
          </MHidden>

          <Grid item xs={12} sm={6}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Entrar
            </LoadingButton>
          </Grid>

          <MHidden width="smUp">
            <Grid item xs={12} sm={6} sx={{ mt: '1rem' }}>
              <Stack direction="row" justifyContent="center">
                <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
                  <Typography variant="button">Esqueci minha senha</Typography>
                </Link>
              </Stack>
            </Grid>
          </MHidden>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
