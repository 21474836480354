import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { qvendeApi } from '../utils/api';
import { isValidToken, setSession } from '../utils/jwt';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SET_USER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  loginWithSocial: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  initialize: () => Promise.initialize()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, logout);
        const response = await qvendeApi.get(`/user/getUserByAcessToken/?accessToken=${accessToken}`);
        const user = response.data;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              ...user
            }
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const login = async (email, password) => {
    const response = await qvendeApi.post('/authentication/login?typeUser=1', {
      email,
      password
    });
    const { accessToken, user } = response?.data;

    setSession(accessToken, logout);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const loginWithSocial = async (accessToken, user) => {
    setSession(accessToken, logout);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const setUser = async (user) => {
    dispatch({
      type: 'SET_USER',
      payload: {
        user
      }
    });
  };

  const register = async (newUser, withUser = false) => {
    const { photoFile, user: userRegister, ...candidate } = newUser;
    const { data } = await qvendeApi.post(withUser ? 'candidate/withUser' : 'candidate', {
      ...candidate,
      user: userRegister,
      number: candidate.number !== '' ? parseInt(candidate.number, 10) : null
    });
    if (photoFile) {
      const formData = new FormData();
      formData.append('file', photoFile);
      await qvendeApi.post(`/user/${data.user.id}/photo`, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
    }
    setSession(data.accessToken, logout);
    window.localStorage.setItem('accessToken', data.accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: data.user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    await qvendeApi.post('user/forgotPassword', {
      email,
      appUrl: process.env.REACT_APP_BASE_URL,
      route: `${PATH_AUTH.changePassword}/?key=`
    });
  };

  const updateProfile = async (superAdmin, superAdminId) => {
    await qvendeApi.put(`super-admin/${superAdminId}`, superAdmin);
    initialize();
  };

  const updateProfilePhoto = async (formData, userId, preview) => {
    setUser({ ...state.user, videoUrl: preview });
    await qvendeApi.post(`user/${userId}/photo`, formData, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
  };

  const updatePassword = async (user, userId) => {
    await qvendeApi.put(`user/${userId}`, user);
    initialize();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        initialize,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        updatePassword,
        updateProfilePhoto,
        loginWithSocial,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
