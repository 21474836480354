import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// contexts
import { SearchProcessProvider } from '../contexts/SearchProcessContext';
import NotificationPreferencesPage from '../pages/dashboard/NotificationPreferencesPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'social', element: <SocialAuth /> },
        { path: 'register', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'change-password', element: <ChangePassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <SearchProcessProvider>
            <DashboardLayout />
          </SearchProcessProvider>
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <DashboardCandidate />
        },
        {
          path: 'publicSelectiveProcess',
          children: [{ path: '/', element: <JoinSelectiveProcess /> }]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <ViewCandidate /> },
            { path: '/edit', element: <EditCandidate /> },
            { path: '/settings', element: <CandidateSettings /> },
            { path: '/addVideoResume', element: <AddVideoResume /> },
            { path: '/viewVideoResume', element: <ViewVideoResume /> }
          ]
        },
        {
          path: 'listSelectiveProcesses',
          children: [
            { path: '/', element: <ListSelectiveProcess /> },
            { path: '/:selectiveProcessId/viewSelectiveProcess', element: <ViewSelectiveProcessGlobal /> }
          ]
        },
        {
          path: 'mySelectiveProcesses',
          element: <ViewCandidateProcess />
        },
        {
          path: 'progressSelectiveProcess/:selectiveProcessId',
          children: [
            { path: '/', element: <ProgressSelectiveProcess /> },
            { path: '/customTest', element: <CustomTest /> },
            { path: '/auxiliaryRequirementsTest', element: <AuxiliaryRequirementsTest /> },
            { path: '/auxiliaryQuestionsTest', element: <AuxiliaryQuestionsTest /> },
            { path: '/addVideoInterview', element: <AddVideoInterview /> },
            { path: '/viewVideoInterview', element: <ViewVideoInterview /> }
          ]
        },
        {
          path: 'tests',
          children: [
            { path: '/', element: <CompetencyTestIntro /> },
            { path: '/competencyTest', element: <CompetencyTest /> }
          ]
        },
        {
          path: 'valuesTest',
          children: [
            { path: '/', element: <ValuesTestIntro /> },
            { path: '/answer', element: <ValuesTest /> }
          ]
        },
        {
          path: 'notification-preferences',
          element: <NotificationPreferencesPage />
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <LandingPage /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const SocialAuth = Loadable(lazy(() => import('../pages/authentication/SocialAuth')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// Dashboard
const ViewCandidateProcess = Loadable(lazy(() => import('../pages/dashboard/ViewCandidateProcess')));
const ViewSelectiveProcessGlobal = Loadable(lazy(() => import('../pages/dashboard/ViewSelectiveProcessGlobal')));
const ListSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/ListSelectiveProcess')));
const JoinSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/JoinSelectiveProcess')));
// Candidate
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const EditCandidate = Loadable(lazy(() => import('../pages/dashboard/EditCandidate')));
const CandidateSettings = Loadable(lazy(() => import('../pages/dashboard/CandidateSettings')));
const AddVideoResume = Loadable(lazy(() => import('../pages/dashboard/AddVideoResume')));
const AddVideoInterview = Loadable(lazy(() => import('../pages/dashboard/AddVideoInterview')));
const ViewVideoInterview = Loadable(lazy(() => import('../pages/dashboard/ViewVideoInterview')));
const ViewVideoResume = Loadable(lazy(() => import('../pages/dashboard/ViewVideoResume')));
const DashboardCandidate = Loadable(lazy(() => import('../pages/dashboard/CandidateDashboard')));
const ViewCandidate = Loadable(lazy(() => import('../pages/dashboard/ViewCandidate')));
const ProgressSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/ProgressSelectiveProcess')));
const CompetencyTestIntro = Loadable(lazy(() => import('../pages/dashboard/Tests/CompetencyTest/index')));
const ValuesTestIntro = Loadable(lazy(() => import('../pages/dashboard/Tests/ValuesTest/index')));
const ValuesTest = Loadable(lazy(() => import('../pages/dashboard/Tests/ValuesTest/ValuesTest')));
const CompetencyTest = Loadable(lazy(() => import('../pages/dashboard/Tests/CompetencyTest/CompetencyTest')));
const CustomTest = Loadable(lazy(() => import('../pages/dashboard/Tests/CustomTest')));
const AuxiliaryQuestionsTest = Loadable(lazy(() => import('../pages/dashboard/Tests/AuxiliaryQuestionsTest')));
const AuxiliaryRequirementsTest = Loadable(lazy(() => import('../pages/dashboard/Tests/AuxiliaryRequirementsTest')));
// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
