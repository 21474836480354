import { Outlet, matchPath } from 'react-router-dom';
import { useLocation } from 'react-router';
import React, { useState } from 'react';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import { Modal, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
//
import DashboardNavbar from './DashboardNavbar';
import { PATH_DASHBOARD } from '../../routes/paths';
import DashboardFooter from './DashboardFooter';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE_WITH_SEARCH = 190;
const APP_BAR_DESKTOP_WITH_SEARCH = 206;
const APP_BAR_MOBILE = 56;
const APP_BAR_DESKTOP = 64;

const RootStyle = styled('div')({
  flexGrow: 1,
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 36,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 36,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [showIframe, setShowIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleButtonClick = () => {
    setShowIframe(true);
    setLoading(true);
  };

  const handleCloseModal = () => {
    setShowIframe(false);
    setLoading(false);
  };

  return (
    <RootStyle>
      <DashboardNavbar />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          backgroundColor: theme.palette.background,
          ...(!!(
            matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
            matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
            pathname.includes('viewSelectiveProcess')
          ) && {})
        }}
      >
        <Outlet />
      </MainStyle>
      <DashboardFooter />
      <Modal open={showIframe} onClose={handleCloseModal}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              minWidth: '38%',
              height: '80vh',
              marginTop: '10vh',
              marginBottom: '10vh',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <CloseIcon
              style={{ cursor: 'pointer', position: 'absolute', top: '0', right: '0', zIndex: '999' }}
              onClick={handleCloseModal}
            />
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute'
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
            <iframe
              title="Formulário de contato"
              className="clickup-embed clickup-dynamic-height"
              src="https://forms.clickup.com/31075871/f/xmbgz-22063/LXKGNG7WXDRPOT4VDF"
              style={{
                maxWidth: '90%',
                maxHeight: '90vh',
                width: '100%',
                height: '100%',
                background: 'transparent',
                border: '1px solid #ccc',
                visibility: loading ? 'hidden' : 'visible'
              }}
              onLoad={handleIframeLoad}
            />
          </div>
        </div>
      </Modal>
    </RootStyle>
  );
}
