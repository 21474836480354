import React, { Component } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { Error } from '@material-ui/icons';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: ''
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    this.setState({ errorMessage: error.message });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
          <Error color="error" style={{ fontSize: 96, marginBottom: 32 }} />
          <Box textAlign="center">
            <Typography variant="h4" component="h1" gutterBottom>
              Desculpe, ocorreu um erro inesperado.
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Você pode copiar a mensagem de erro abaixo e enviá-la junto a um relato do problema para o nosso suporte,
              através do botão à esquerda da sua tela.
            </Typography>
            {this.state.errorMessage && (
              <Typography variant="body1" color="error" style={{ marginTop: 20 }}>
                {this.state.errorMessage}
              </Typography>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            href={PATH_DASHBOARD.root}
            style={{
              margin: '20px',
              borderRadius: '10px',
              padding: '12px 24px',
              fontWeight: 'bold',
              fontSize: '16px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            Voltar ao início
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
