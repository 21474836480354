import React from 'react';
import PropTypes from 'prop-types';
// material
import { Typography, Stack } from '@material-ui/core';

LabelFields.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool
};

export default function LabelFields({ title, required }) {
  return (
    <Stack direction="row">
      <Typography variant="body2" color="primary" fontWeight="600" sx={{ mb: !required ? '0.1rem' : undefined }}>
        {title}
      </Typography>
      {required && <Typography color="primary">*</Typography>}
    </Stack>
  );
}
