import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
// material
import { Avatar, useTheme } from '@material-ui/core';

// ----------------------------------------------------------------------

const MAvatar = forwardRef(({ color = 'default', sx, src, children, ...other }, ref) => {
  const theme = useTheme();
  const [image, setImage] = useState(src);

  useEffect(() => setImage(src), [src]);

  if (color === 'default') {
    return (
      <Avatar
        ref={ref}
        sx={sx}
        imgProps={{
          onError: () => {
            setImage(null);
          }
        }}
        {...other}
        {...(image && { src: image })}
      >
        {children}
      </Avatar>
    );
  }

  return (
    <Avatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        ...sx
      }}
      imgProps={{
        onError: () => {
          setImage(null);
        }
      }}
      {...other}
      {...(image && { src: image })}
    >
      {children}
    </Avatar>
  );
});

MAvatar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  src: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error'])
};

export default MAvatar;
