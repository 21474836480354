import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

// ----------------------------------------------------------------------

const axiosMockAdapter = new AxiosMockAdapter(axios.create(), {
  delayResponse: 0
});

export default axiosMockAdapter;
