import { format, getTime, formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy', {
    locale: ptBR
  });
}

export function fDateInput(date) {
  return format(new Date(date), 'yyyy-MM-dd', {
    locale: ptBR
  });
}

export function fTimeInput(date) {
  return format(new Date(date), 'HH:mm', {
    locale: ptBR
  });
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimePTBR(date) {
  return format(new Date(date), "dd/MM/yyyy 'às' hh:mm", {
    locale: ptBR
  });
}

export function fDateTimeStringView(date) {
  return format(new Date(date), 'dd/MMM/yyyy', {
    locale: ptBR
  });
}

export function fDateTimeViewMonthYear(date) {
  return format(new Date(date), "MMM 'de' yyyy", {
    locale: ptBR
  });
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: false,
    locale: ptBR
  });
}
