import React, { useState } from 'react';
// material
import { Card, Stack, Container, Typography, Link, Divider, Modal, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import LinkedinLogin from './LinkedinLogin';
import Logo from '../../components/logos/Logo';

// ----------------------------------------------------------------------

export default function Login() {
  const [showIframe, setShowIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleButtonClick = () => {
    setShowIframe(true);
    setLoading(true);
  };

  const handleCloseModal = () => {
    setShowIframe(false);
    setLoading(false);
  };
  return (
    <Page
      title="Login | Qvende"
      sx={{
        backgroundImage: 'url(/static/auth/brackground.svg)',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Modal open={showIframe} onClose={handleCloseModal}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              minWidth: '38%',
              height: '80vh',
              marginTop: '10vh',
              marginBottom: '10vh',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <CloseIcon
              style={{ cursor: 'pointer', position: 'absolute', top: '0', right: '0', zIndex: '999' }}
              onClick={handleCloseModal}
            />
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute'
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
            <iframe
              title="Formulário de contato"
              className="clickup-embed clickup-dynamic-height"
              src="https://forms.clickup.com/31075871/f/xmbgz-22063/LXKGNG7WXDRPOT4VDF"
              style={{
                maxWidth: '90%',
                maxHeight: '90vh',
                width: '100%',
                height: '100%',
                background: 'transparent',
                border: '1px solid #ccc',
                visibility: loading ? 'hidden' : 'visible'
              }}
              onLoad={handleIframeLoad}
            />
          </div>
        </div>
      </Modal>
      <Container maxWidth="lg">
        <Card>
          <Stack direction="row">
            <MHidden width="mdDown">
              <Stack
                alignItems="start"
                sx={{
                  background: 'linear-gradient(135deg, #5B6DE5 0%, #00107A 100%)',
                  borderRadius: '20px 0px 0px 20px',
                  pl: '4.25rem',
                  pr: { md: '3rem', lg: '4.938rem' },
                  py: '4.438rem'
                }}
              >
                <Logo height="45px" color="white" />
                <Typography
                  fontWeight="700"
                  variant="h1"
                  color="primary.contrastText"
                  sx={{ mt: '4.091rem', width: { md: '19rem', lg: '23.75rem' } }}
                >
                  Sua <br />
                  carreira <br />
                  em vendas <br />
                  acelera <br />
                  aqui!
                </Typography>
              </Stack>
            </MHidden>
            <Stack
              sx={{
                width: '100%',
                pl: { xs: '1rem', sm: '3.875rem' },
                pr: { xs: '1rem', sm: '4.938rem' },
                pt: '2.688rem'
              }}
            >
              <Typography fontWeight="bold" variant="h3" color="primary.light">
                Bem vindo(a) à QVende
              </Typography>
              <Stack sx={{ mt: '2.25rem' }}>
                <LinkedinLogin />
              </Stack>
              <Divider sx={{ mb: '1.25rem', mt: '0.5rem' }}>
                <Typography variant="body2" color="grey.600" sx={{ transform: 'translate(0, 10px)' }}>
                  OU
                </Typography>
              </Divider>

              <Typography variant="h6" color="grey.600" sx={{ mb: '1.063rem', mt: '1rem' }}>
                Entre com seu e-mail
              </Typography>
              <LoginForm />

              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: '3.125rem', mb: { xs: '1.5rem', lg: 0 } }}
                alignItems="center"
              >
                <Typography variant="subtitle1" color="grey.500">
                  Ainda não tem uma conta?
                </Typography>
                <Link href="/auth/register">
                  <Typography variant="h6" color="primary">
                    Cadastre-se
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
}
