import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { qvendeApi } from '../utils/api';

// ----------------------------------------------------------------------

const initialState = {
  querys: {
    roleName: '',
    city: '',
    state: '',
    page: '1',
    contractType: [],
    payment: [],
    commissionPayment: [],
    vacanciesDeficiencies: '',
    schooling: [],
    cnh: [],
    jobProfile: [],
    sort: 'ASC'
  },
  changeQuerys: () => null,
  resetQuerys: () => null,
  searchProcess: () => null,
  currentPage: 1,
  selectiveProcesses: null,
  isSearching: true,
  error: null
};

const SearchProcessContext = createContext(initialState);

SearchProcessProvider.propTypes = {
  children: PropTypes.node
};

function SearchProcessProvider({ children }) {
  const { user } = useAuth();
  const [querys, setQuerys] = useState(initialState.querys);
  const [currentPage, setCurrentPage] = useState(initialState.currentPage);
  const [selectiveProcesses, setSelectiveProcesses] = useState(null);
  const [isSearching, setIsSearching] = useState(true);
  const [error, setError] = useState(null);

  const getQuerys = useCallback(() => {
    let stringQuerys = '';
    const { contractType, payment, commissionPayment, schooling, cnh, jobProfile, ...querysTemp } = querys;
    Object.keys(querysTemp).forEach((item) => {
      if (item !== 'roleName') {
        stringQuerys += `&${item}=${querysTemp[item]}`;
      }
    });
    if (contractType !== '')
      contractType.forEach((item) => {
        stringQuerys += `&contractType=${item}`;
      });
    if (payment !== '')
      payment.forEach((item) => {
        stringQuerys += `&payment=${item}`;
      });
    if (commissionPayment !== '')
      commissionPayment.forEach((item) => {
        stringQuerys += `&commissionPayment=${item}`;
      });
    if (schooling !== '')
      schooling.forEach((item) => {
        stringQuerys += `&schooling=${item}`;
      });
    if (cnh !== '')
      cnh.forEach((item) => {
        stringQuerys += `&cnh=${item}`;
      });
    if (jobProfile !== '')
      jobProfile.forEach((item) => {
        stringQuerys += `&jobProfile=${item}`;
      });
    return stringQuerys;
  }, [querys]);

  useEffect(() => {
    if (isSearching) {
      qvendeApi
        .get(
          `selectiveProcess/listAll?candidateId=${
            user?.candidate?.id
          }&isPagination=1&page=${currentPage}&limit=10&status=1${getQuerys()}&roleName=${querys.roleName}`
        )
        .then(({ data }) => {
          setSelectiveProcesses(data);
        })
        .catch((e) => setError(e))
        .finally(() => setIsSearching(false));
    }
  }, [currentPage, getQuerys, isSearching, querys, user]);

  const changeQuerys = (newQuerys) => {
    setQuerys({ ...querys, ...newQuerys });
  };

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const searchProcess = () => {
    setIsSearching(true);
  };

  const resetQuerys = () => setQuerys({ ...initialState.querys });

  return (
    <SearchProcessContext.Provider
      value={{
        querys,
        currentPage,
        changeQuerys,
        resetQuerys,
        selectiveProcesses,
        isSearching,
        changeCurrentPage,
        error,
        searchProcess
      }}
    >
      {children}
    </SearchProcessContext.Provider>
  );
}

export { SearchProcessProvider, SearchProcessContext };
