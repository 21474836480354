export const NotificationTypeIcon = {
  1: {
    icon: '/static/icons/selective_icon.svg'
  },
  2: {
    icon: '/static/icons/schedule_icon.svg'
  },
  3: {
    icon: '/static/icons/selective_icon.svg'
  },
  4: {
    icon: '/static/icons/selective_icon.svg'
  }
};
