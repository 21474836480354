import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import workFill from '@iconify/icons-ic/baseline-work';
import accountBox from '@iconify/icons-ic/baseline-account-box';
import ExitFill from '@iconify/icons-radix-icons/exit';
import searchFill from '@iconify/icons-eva/search-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, MenuItem } from '@material-ui/core';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { MHidden, MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Início',
    icon: homeFill,
    linkTo: PATH_DASHBOARD.root
  },
  {
    label: 'Buscar Vagas',
    icon: searchFill,
    linkTo: PATH_DASHBOARD.general.listSelectiveProcesses
  },
  {
    label: 'Meu Currículo',
    icon: accountBox,
    linkTo: PATH_DASHBOARD.user.root
  },
  {
    label: 'Minhas Vagas',
    icon: workFill,
    linkTo: PATH_DASHBOARD.general.mySelectiveProcesses
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login);
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton ref={anchorRef} onClick={handleOpen} color="inherit">
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220, py: 2 }}>
        <MHidden width="mdUp">
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ))}
        </MHidden>
        <MenuItem
          to={PATH_DASHBOARD.user.settings}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={settings2Fill}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />
          Minha conta
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
          <Box
            component={Icon}
            icon={ExitFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />
          Sair
        </MenuItem>
      </MenuPopover>
    </>
  );
}
