import axios from 'axios';

const qvendeApi = axios.create({
  baseURL: process.env.REACT_APP_API_QVENDE
});

qvendeApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export { qvendeApi };
