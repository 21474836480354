import { Link as ScrollLink } from 'react-scroll';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Link, Divider, Container, Typography, Stack, useMediaQuery } from '@material-ui/core';
import { MHidden } from '../../components/@material-extend/index';
//
import Logo from '../../components/logos/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey['100']
}));

// ----------------------------------------------------------------------

export default function DashboardFooter() {
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <RootStyle>
      <Divider sx={{ mb: '1.875rem', mt: '7.375rem' }} />
      <Container maxWidth="lg">
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={5} justifyContent="space-between">
          <Stack alignItems={{ xs: 'center', lg: 'start' }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo color="dark" />
            </ScrollLink>
            <Typography variant="subtitle1" fontWeight="500" sx={{ mb: '1.5rem', mt: '2.375rem' }}>
              Startup acelerada por:
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'start' }} spacing={3}>
              <Stack component="img" src="/static/icons/tecnova.svg" alt="Selo tecnova" />
              <Stack
                component="img"
                src="/static/icons/LOGO_INOVATIVA_BrasilDark.png"
                alt="INOVATIVA"
                sx={{ width: '9.125rem' }}
              />
            </Stack>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5} justifyContent="center">
            <Stack alignItems={{ xs: 'center', sm: 'start' }} justifyContent="start">
              <Typography variant="subtitle1" color="grey.600" fontWeight="700" sx={{ mb: '1.5rem' }}>
                PLATAFORMA
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                fontWeight="700"
                sx={{ mb: '1rem', cursor: 'pointer' }}
                onClick={() => window.location.reload()}
              >
                Para Candidatos
              </Typography>
              <Link href={process.env.REACT_APP_COMPANY_URL} target="_blank" sx={{ mb: '1rem' }}>
                <Typography variant="subtitle1" color="primary" fontWeight="700">
                  Para Empresas
                </Typography>
              </Link>
              {/*               <Typography variant="subtitle1" color="primary" fontWeight="700" sx={{ mb: '1rem', cursor: 'pointer' }}>
                FAQ
              </Typography> */}
              {/*               <Typography variant="subtitle1" color="primary" fontWeight="700" sx={{ mb: '1rem', cursor: 'pointer' }}>
                Fale Conosco
              </Typography> */}
              <Link href="/auth/register" target="_blank" sx={{ mb: '2.125rem' }}>
                <Typography href="/auth/register" variant="subtitle1" color="primary" fontWeight="700">
                  Cadastre-se Grátis
                </Typography>
              </Link>
            </Stack>
            <Stack alignItems={{ xs: 'center', sm: 'start' }} justifyContent="start">
              <Typography variant="subtitle1" color="grey.600" fontWeight="700" sx={{ mb: '1.5rem' }}>
                LEGAL
              </Typography>
              <Link href="static/files/POLÍTICA_DE_PRIVACIDADE_QVENDE.pdf" target="_blank">
                <Typography variant="subtitle1" color="primary" fontWeight="700" sx={{ mb: '1rem' }}>
                  Política de Privacidade
                </Typography>
              </Link>
              <Link href="static/files/TERMOS_DE_USO_QUE_VENDE.pdf" target="_blank">
                <Typography variant="subtitle1" color="primary" fontWeight="700" sx={{ mb: '1rem' }}>
                  Termos de Uso
                </Typography>
              </Link>
              {/* <Typography variant="subtitle1" color="primary.lighter" fontWeight="700" sx={{ mb: '2.125rem' }}>
                      Política de Cookies
                    </Typography> */}
            </Stack>
            <Stack alignItems={{ xs: 'center', sm: 'start' }} justifyContent="start" spacing={3}>
              <Typography variant="subtitle1" color="grey.600" fontWeight="700">
                CONTATO
              </Typography>
              <Typography variant="subtitle1" fontWeight="400" sx={{ mb: '2.125rem' }}>
                candidato@quevende.com.br
              </Typography>
              <Stack alignItems="center">
                <Typography sx={{ mb: '1.5rem', fontFamily: 'Red Hat Display', fontWeight: '500', fontSize: '18px' }}>
                  Siga-nos nas redes sociais
                </Typography>
                <Stack direction="row" spacing={4} sx={{ ml: '1rem' }}>
                  <Link href="https://instagram.com/sigaqvende?utm_medium=copy_link" target="_blank">
                    <img src="/static/icons/insta_icon.png" alt="insta" />
                  </Link>
                  <Link href="https://www.linkedin.com/company/sigaqvende" target="_blank">
                    <img src="/static/icons/linkedin_icon.png" alt="linkedin" />
                  </Link>
                  {/*                   <Link href="https://web.whatsapp.com/send?phone=558488734257" target="_blank">
                    <img src="/static/icons/whatsapp_Icon.svg" alt="whatsapp" />
                  </Link> */}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Divider sx={{ mb: '0.75rem', mt: '1.313rem' }} />
      <Container maxWidth="lg">
        <Stack alignItems="center" sx={{ pb: '0.75rem' }}>
          <Typography variant="subtitle1" fontWeight="500">
            QVende © 2022 | Todos os direitos reservados.
          </Typography>
        </Stack>
      </Container>
    </RootStyle>
  );
}
