import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
// hook
import useAuth from '../hooks/useAuth';
// utils
import { qvendeApi } from '../utils/api';
// ----------------------------------------------------------------------

const initialState = {
  notifications: [],
  readAllNotifications: () => null,
  readNotification: () => null,
  getNotification: () => null
};

const NotificationContext = createContext(initialState);

NotificationProvider.propTypes = {
  children: PropTypes.node
};

function NotificationProvider({ children }) {
  const { isAuthenticated, user } = useAuth();
  const [notifications, setNotifications] = useState([]);

  const getNotification = async () => {
    try {
      const { data } = await qvendeApi.get(`notification/list/${user.id}/?isPagination=1`);
      setNotifications(data.rows);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      getNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  const readAllNotifications = async () => {
    try {
      await qvendeApi.put(`notification/readAll/${user.id}`);
      const { data } = await qvendeApi.get(`notification/list/${user.id}/?isPagination=1`);
      setNotifications(data.rows);
    } catch (error) {
      console.log(error);
    }
  };

  const readNotification = async (notificationId) => {
    const index = notifications.findIndex((notification) => notification.id === notificationId);
    if (notifications[index].unread === true) {
      try {
        const notification = notifications[index];
        notification.unread = false;
        await qvendeApi.put(`notification/${notifications[index].id}`, notification);
        notifications[index] = { ...notification };
        setNotifications([...notifications]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        readAllNotifications,
        readNotification,
        getNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export { NotificationProvider, NotificationContext };
